import React from 'react'
import { Row, Tab, Col, Form, Button } from 'react-bootstrap';
import { FaFilter } from 'react-icons/fa'
import { WithContext as ReactTags } from 'react-tag-input';

export default function CollectionFilters(props) {
    const colors = [{id:'w', name:'White'},{id:'u', name:'Blue'},{id:'b', name:'Black'},{id:'r', name:'Red'},{id:'g', name:'Green'}];

    const capitalize = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
        <>
        <Tab.Content><FaFilter /> Filters</Tab.Content>
        <Row>
            <Col sm={12} md={6} lg={4} xl={3}>
                <Form.Label>Colors</Form.Label>
                <Form>
                    <Form.Group>
                        <Form.Label>Must include all these colors</Form.Label>
                        <ReactTags
                            classNames={{
                                tagInputField: 'form-control'
                            }}
                            placeholder='Type to add colors'
                            tags={props.filterColor.mustIncludeAll}
                            suggestions={colors}
                            inline
                            labelField='name'
                            handleAddition={(tag) => {
                                // Check if tag is allowed
                                if(colors.some(item => item.name.toLowerCase() === tag.name.toLowerCase())) {
                                    tag.name = capitalize(tag.name.toLowerCase())
                                    tag.id = tag.name === 'Blue' ? 'u' : tag.name.charAt(0).toLowerCase()

                                    // Add the tag to the tag list
                                    props.setFilterColor({...props.filterColor, mustIncludeAll:[...props.filterColor.mustIncludeAll, tag]});
                                }
                            }}
                            handleDelete={(i) => {
                                // Delete the tag at index i
                                let newFilterColor = props.filterColor.mustIncludeAll
                                delete newFilterColor.splice(i,1);
                                props.setFilterColor({...props.filterColor, mustIncludeAll:newFilterColor});
                            }}
                        />
                    </Form.Group>
                    <Form.Group className='mt-3'>
                        <Form.Label>Must <u>not</u> include all these Colors</Form.Label>
                        <ReactTags
                            classNames={{
                                tagInputField: 'form-control'
                            }}
                            inputFieldPosition='inline'
                            placeholder='Type to add colors'
                            tags={props.filterColor.mustExcludeAll}
                            suggestions={colors}
                            labelField='name'
                            handleAddition={(tag) => {
                                // Check if tag is allowed
                                if(colors.some(item => item.name.toLowerCase() === tag.name.toLowerCase())) {
                                    tag.name = capitalize(tag.name.toLowerCase())
                                    tag.id = tag.name === 'Blue' ? 'u' : tag.name.charAt(0).toLowerCase()

                                    // Add the tag to the tag list
                                    props.setFilterColor({...props.filterColor, mustExcludeAll:[...props.filterColor.mustExcludeAll, tag]})
                                }
                            }}
                            handleDelete={(i) => {
                                // Delete the tag at index i
                                let newFilterColor = props.filterColor.mustExcludeAll
                                delete newFilterColor.splice(i,1);
                                props.setFilterColor({...props.filterColor, mustExcludeAll:newFilterColor});
                            }}
                        />
                    </Form.Group>
                </Form>
            </Col>
            <Col>
            </Col>
        </Row>
        <Button variant='secondary' className='mt-2' onClick={e => {props.applyFilters()}}>Apply</Button>
        </>
    )
}
