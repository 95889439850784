import React, { useEffect, useRef, useState } from 'react'
import { Accordion, ButtonToolbar, Button, Container, Image, InputGroup, Row, Form } from 'react-bootstrap'
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { FaPlus } from 'react-icons/fa';
import { useLocation } from 'react-router';
import DeckTable from '../Components/DeckTable';

export default function Deck() {

    const id = new URLSearchParams(useLocation().search).get('id');
    const [deckOwner, setDeckOwner] = useState(false)
    const [deck, setDeck] = useState([])
    const [focusPreview, setFocusPreview] = useState({'image_uris':{'png':''},'mana_cost':''})
    const [preview, setPreview] = useState({'image_uris':{'png':''},'mana_cost':''})
    const [maybeBoardCount, setMaybeBoardCount]  = useState(0)
    const searchAddCards = useRef(null)
    const [searchIsLoading, setSearchIsLoading] = useState(false)
    const [autoComplete, setAutoComplete] = useState([])
    const [search, setSearch] = useState([])

    useEffect(() => {
        let isMounted = true

        const fetchDecks = () => {
            fetch('https://api.magiccollect.nl/deck/?id=' + id)
            .then((response) => {
                return response.json();
            })
            .then((responseJSON) => {
                if (isMounted){
                    setDeck(responseJSON);
                    setFocusPreview(responseJSON.main.commander.count > 0 ? responseJSON.main.commander.cards[0] : {'image_uris':{'png':''},'mana_cost':''})
                    setPreview(responseJSON.main.commander.count > 0 ? responseJSON.main.commander.cards[0] : {'image_uris':{'png':''},'mana_cost':''})
                    setMaybeBoardCount(responseJSON.deck.optional)
                    if( responseJSON.deck.createdBy === JSON.parse(sessionStorage.getItem('magiccollect')).id) {
                        setDeckOwner(true)
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            });
        }
        fetchDecks()
        return () => { isMounted = false } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    function deckAddCard (card) {
        card = card.length > 0 ? card[0] : search[0]
        if(card) {
             fetch('https://api.magiccollect.nl/deck/addCard/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'accesstoken': JSON.parse(sessionStorage.getItem('token'))
                },
                body: JSON.stringify({
                    'deckId': id,
                    'cardName': card
                })
            })
            .then((response) => {
                return response.json();
            })
            .then((responseJSON) => {
                let matchFound = false;
                let changedDeck = deck;

                // Check if card is already in the deck
                for (var key in changedDeck['main'][responseJSON.category]['cards']) {
                    if(changedDeck['main'][responseJSON.category]['cards'][key]['id'] === responseJSON.card.id && changedDeck['main'][responseJSON.category]['cards'][key]['finish'] === responseJSON.card.finish) {
                        matchFound = true
                        changedDeck['main'][responseJSON.category]['cards'][key]['ids'] = changedDeck['main'][responseJSON.category]['cards'][key]['ids'] + ',' + responseJSON.card.ids
                        changedDeck['main'][responseJSON.category]['cards'][key]['count']++
                    } 
                }
                if(!matchFound) {
                    changedDeck['main'][responseJSON.category]['cards'].push(responseJSON.card)
                }
                changedDeck['main'][responseJSON.category]['count']++

                // Sort array?
                function sortCards(a,b) {
                    if(a.name < b.name) {return -1}
                    if(a.name > b.name) {return 1}
                    return 0
                }
                changedDeck['main'][responseJSON.category]['cards'].sort(sortCards)

                // Update useState
               setDeck({...changedDeck})
               setSearch([])
               searchAddCards.current.clear();

            })
            .catch((error) => {
                console.error(error)
            })
        }
    }

    const renderTableMain = (data,index) => {
        let category = data[0]
        data = data[1]
        return(
            <DeckTable
                key={'main_' + category}
                deckOwner={deckOwner}
                format={deck.deck.format}
                board='main'
                category={category}
                title={data.title}
                icon={data.icon}
                count={data.count}
                data={data.cards}
                focusPreview={focusPreview}
                setFocusPreview={setFocusPreview}
                setPreview={setPreview}
            />
        )
    }
    const renderTableInactive = (data,index) => {
        let category = data[0]
        data = data[1]
        return(
            <DeckTable
                key={'main_' + category}
                deckOwner={deckOwner}
                format={deck.deck.format}
                board='inactive'
                category={category}
                title={data.title}
                icon={data.icon}
                count={data.count}
                data={data.cards}
                focusPreview={focusPreview}
                setFocusPreview={setFocusPreview}
                setPreview={setPreview}
            />
        )
    }
    return (
        <>
        { deck.deck ?
            <div>
                <div className='deck-header' style={{backgroundImage: 'url(' + deck.deck.imageThumbnail + ')'}}>
                    <div style={{width:'100%',height:'100%',background: 'linear-gradient(70deg, rgba(33,37,41,0.9) 40%, rgba(255,255,255,0) 100%)'}}>
                        <Container className='d-flex flex-column justify-content-center' style={{width:'100%',height:'100%',color:'#fff'}}>
                            <div style={{fontSize:'3rem'}}>{deck.deck.name}</div>
                            <div style={{fontSize:'1.5rem'}}>{deck.deck.format}</div>
                        </Container>
                    </div>
                </div>

            </div>
            : ''
        }
        <Container className='py-3'>
            <Row>
                <div className='py-2 d-none d-xl-block' style={{width:'300px',flex:'0 0 auto'}}>
                    <div className='sticky-preview' >
                        <Image src={preview.image_uris ? preview.image_uris.png : preview.card_faces[0].image_uris.png} style={{width:'100%'}}/>
                        { preview.finish === 'foil' && <Image src='http://www.magiccollect.nl/content/images/foil_card.png' className='foil-overlay' /> }
                        <div>
                            {preview.name}
                        </div>
                    </div>
                </div>
                <div style={{width:'10%',maxWidth:'100%',flex:'1 1 auto'}}>
                    { deckOwner ? 
                    <ButtonToolbar className="justify-content-end py-2 mt-3 border-top border-bottom border-dark">
                        <Form>
                            <InputGroup size="sm" style={{paddingLeft: '2rem'}}>
                                <AsyncTypeahead
                                    id='searchAddCards'
                                    ref={searchAddCards}
                                    isLoading={searchIsLoading}
                                    defaultSelected={search}
                                    highlightOnlyResult
                                    onSearch={(query) => {
                                        setSearchIsLoading(true)
                                        fetch(`https://api.scryfall.com/cards/autocomplete?q=${query}`)
                                        .then(resp => resp.json())
                                        .then(json => {
                                            setSearchIsLoading(false)
                                            setAutoComplete(json.data)
                                        });
                                    }}
                                    onChange={ selected => {
                                        setSearch(selected)
                                        deckAddCard(selected)
                                    }}
                                    options={autoComplete}
                                    style={{width:'350px'}}
                                />
                                <Button className='d-flex align-items-center' variant="outline-secondary" onClick={deckAddCard}><FaPlus /></Button>
                            </InputGroup>
                        </Form>
                    </ButtonToolbar>
                    : ''}
                    <div className="decklist">
                        <div className="deckTables">
                            {
                                deck.main && Object.entries(deck.main).map(renderTableMain)
                            }
                        </div>
                    </div>
                    {
                        maybeBoardCount > 0 ?   
                            <Accordion flush defaultActiveKey="">
                                <Accordion.Item eventKey="main">
                                    <Accordion.Header>
                                        Maybeboard ({maybeBoardCount})
                                    </Accordion.Header>
                                    <Accordion.Body className="decklist">
                                        <div className="deckTables">
                                            {
                                                deck.inactive && Object.entries(deck.inactive).map(renderTableInactive)
                                            }
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        : ''
                    }
                </div>
            </Row>

        </Container>
        </>
    )
}
