import React from 'react'
import { Image, Modal, Row, Col, Table, FormControl} from 'react-bootstrap'

export default function CollectionDetail(props) {

    if(props.detailData.image_uris) {
        return (
            <>
            <Modal dialogClassName="modal-90w" show={props.showDetail} onHide={() => {props.setShowDetail(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.detailData.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={12} md={6} lg={4} xxl={3}>
                            <Image style={{width:'100%'}}src={props.detailData.image_uris.png} />
                        </Col>
                        <Col sm={12} md={6} lg={4} xxl={3}>
                            <Table borderless className="detailData mb-0">
                                <tbody>
                                    <tr>
                                        <td>Name:</td>
                                        <td>{props.detailData.name}</td>
                                    </tr>
                                    <tr>
                                        <td>Set:</td>
                                        <td>{props.detailData.set_name}</td>
                                    </tr>
                                    <tr>
                                        <td>Number:</td>
                                        <td>{props.detailData.collector_number}</td>
                                    </tr>
                                    <tr>
                                        <td>Type:</td>
                                        <td>{props.detailData.type_line}</td>
                                    </tr>
                                    <tr>
                                        <td>Manacost:</td>
                                        <td>{props.detailData.mana_cost}</td>
                                    </tr>
                                    <tr>
                                        <td>Paid:</td>
                                        <td>
                                            <FormControl
                                                type="number"
                                                value='0.00'
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Value:</td>
                                        <td>
                                            <FormControl
                                                type="number"
                                                value='0.00'
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Have:</td>
                                        <td>
                                            <FormControl
                                                type="number"
                                                value='0'
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Want:</td>
                                        <td>
                                            <FormControl
                                                type="number"
                                                value='0'
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan='2'>
                                            Storage
                                            <FormControl
                                                as="textarea"
                                                placeholder='Where is this card stored...'
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan='2'>
                                            Remarks
                                            <FormControl
                                                as="textarea"
                                                placeholder='Put notes here...'
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            </>
        )
    } else {
        return (
            <>
            </>
        )
    }
}
