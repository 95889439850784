import React from 'react'
import { Dropdown, Image, Table } from 'react-bootstrap'
import { FaPlusCircle, FaMinusCircle, FaStar } from 'react-icons/fa'

export default function DeckTable(props) {
    if(props.count > 0) {
        const renderTableRow = (card,index) => {
            const ids = card.ids.split(',')
            return(
                <tr 
                    className={props.focusPreview.ids === card.ids ? 'table-active' : ''}
                    //key={props.board + '_' + props.category + '_' + card.scryfall_id + '_' + card.finish} 
                    key={card.ids}
                    onMouseEnter={() => {props.setPreview(card)}} 
                    onMouseLeave={() => {props.setPreview(props.focusPreview)}}
                    onClick={() => {
                        props.setPreview(card);
                        props.setFocusPreview(card);
                    }}
                >
                    <td>{card.count}</td>
                    <td>
                        <span className='d-flex align-items-center'>
                            {card.name}
                            {
                            card.finish === 'foil' 
                                ? <FaStar className='mx-1' style={{color:'gold'}}/> 
                                : card.finish === 'etched' 
                                    && <FaStar className='mx-1' style={{color:'palevioletred'}}/> 
                            }
                        </span>
                    </td>
                    <td>
                        { props.deckOwner ? 
                        <Dropdown drop='up'>
                            <Dropdown.Toggle size='sm' variant='secondary' className='card-menu'></Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item>View details</Dropdown.Item>
                                <Dropdown.Divider />
                                { 
                                (card.finishes.includes('nonfoil') && card.finish !== 'nonfoil')
                                    && <Dropdown.Item onClick={(e)=>{alert('SET ' + ids[0] + ' TO NONFOIL')}}>Use non-foil version</Dropdown.Item>
                                }
                                { 
                                    (card.finishes.includes('foil') && card.finish !== 'foil')
                                    && <Dropdown.Item onClick={(e)=>{alert('SET ' + ids[0] + ' TO FOIL')}}>Use foil version</Dropdown.Item>
                                }
                                { 
                                    (card.finishes.includes('etched') && card.finish !== 'etched')
                                    && <Dropdown.Item onClick={(e)=>{alert('SET ' + ids[0] + ' TO ETCHED')}}>Use etched version</Dropdown.Item>
                                }
                                { 
                                    (card.finishes.includes('glossy') && card.finish !== 'glossy')
                                    && <Dropdown.Item onClick={(e)=>{alert('SET ' + ids[0] + ' TO GLOSSY')}}>Use glossy version</Dropdown.Item>
                                }
                                <Dropdown.Item onClick={(e)=>{alert('CHANGE PRINTINGS FOR ' + card.ids)}}>Change printing</Dropdown.Item>
                                <Dropdown.Divider />
                                {
                                    // Dropdown option for promote/demote commander
                                    props.format === 'Commander'
                                    ? props.category !== 'commander'
                                        ? (props.category === 'creatures' && card.type_line.includes('Legendary'))
                                            && <Dropdown.Item onClick={(e)=>{alert('MAKE ' + ids[0] + ' COMMANDER')}}>Make commander</Dropdown.Item>
                                        : <Dropdown.Item onClick={(e)=>{alert('MOVE ' + ids[0] + ' TO DECK ')}}>Move to deck</Dropdown.Item>
                                    : ''
                                }
                                {
                                    // Dropdown option for main/inactive
                                    props.board === 'main'
                                    ? <Dropdown.Item onClick={(e)=>{alert('MOVE ' + ids[0] + ' TO MAYBE')}}>Move to maybeboard</Dropdown.Item>
                                    : <Dropdown.Item onClick={(e)=>{alert('MOVE ' + ids[0] + ' TO MAIN')}}>Move to mainboard</Dropdown.Item>
                                }
                                <Dropdown.Item onClick={(e)=>{alert('DUPLICATE ' + ids[0])}}><FaPlusCircle className='dropdown-icon green'/>Add another</Dropdown.Item>
                                {
                                    card.count > 1
                                    ?  <>
                                        <Dropdown.Item onClick={(e)=>{alert('DELETE ' + ids[0])}}><FaMinusCircle className='dropdown-icon red'/>Remove 1</Dropdown.Item>
                                        <Dropdown.Item onClick={(e)=>{alert('DELETE ' + card.ids)}}><FaMinusCircle className='dropdown-icon red'/>Remove all</Dropdown.Item>
                                      </>
                                    : <Dropdown.Item onClick={(e)=>{alert('DELETE ' + ids[0])}}><FaMinusCircle className='dropdown-icon red'/>Remove</Dropdown.Item>
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                        : ''
                        }
                    </td>
                </tr>
            )
        }
        return (
            <Table size="sm" className="deckTable" hover>
                <thead>
                    <tr>
                        <th colSpan="3">
                            <div className="d-flex align-items-center">
                                <Image
                                    src={props.icon}
                                    height="20"
                                />
                                <span style={{margin:"0px 5px"}}>{props.title}</span>
                                <span>({props.count})</span>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.data.map(renderTableRow)
                    }
                </tbody>
            </Table>
        )
    } else {
        return false
    }
}
