import React from 'react'
import { Card, Col, Image } from 'react-bootstrap'
import { FaLock } from 'react-icons/fa'


export default function DeckCard(props) {
    const renderColors = (color,index) => {
        const allowedColors = 'WUBRG'
        if(color && allowedColors.includes(color)) {
            return(
                <Image 
                    src={'https://www.magiccollect.nl/content/images/manaSymbols/' + color.toLowerCase() + '.svg'}
                    height={20}
                    className="manaSymbol"
                    key={color}
                />
            )
        }
    }

    return (
            <Col sm={6} lg={4} xxl={3} className="deckCol" key={props.id}>
                <Card className="deckCard">
                    <a className="deckCard-wrapper" href={
                        parseInt(props.data.private) === 1
                        ? props.authorId === JSON.parse(sessionStorage.getItem('magiccollect')).id
                            ? "/db/deck?id=" + props.id
                            : "#"
                        : "/db/deck?id=" + props.id
                    }>
                        <Card.Img 
                            height={200}
                            varant="top"
                            src={props.img}
                            onError={(e)=>{e.target.onerror = null; e.target.src="https://c1.scryfall.com/file/scryfall-cards/art_crop/front/4/8/48484d4d-6000-4e7b-87cf-cabfe4e19b0e.jpg?1632415778"}}
                        />
                        <Card.ImgOverlay>
                            <Card.Title className='d-flex justify-content-between'>
                                {props.title}
                                {parseInt(props.data.private) === 1 && <FaLock style={{color:'gray'}}/>}
                            </Card.Title>
                            <Card.Subtitle>
                                {props.format}
                            </Card.Subtitle>
                            <Card.Footer>
                        <small>
                            <Image 
                                src={props.authorImg}
                                onError={(e)=>{e.target.onerror = null; e.target.src="https://www.magiccollect.nl/content/images/users/no-profile.png"}}
                                height="25"
                                roundedCircle
                                className="authorImage"
                            />
                            {props.author}
                        </small>
                        <small>
                            {props.colors.map(renderColors)}
                        </small>
                    </Card.Footer>
                        </Card.ImgOverlay>
                    </a>
                </Card>
            </Col>
    )
}
