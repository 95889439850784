import React from 'react'
import { Navbar, Container, Nav, NavDropdown, Image } from 'react-bootstrap'
import logo from '../Assets/mc-logo-dark.png'
import { NavLink, withRouter } from 'react-router-dom'

function MainNavbar(props) {    
    return (
        <header className={"header"}>
            <Navbar bg="dark" variant="dark" expand="lg">
                <Container>
                    <Navbar.Brand href="#home">
                        <img
                            alt=""
                            src={logo} 
                            height="30"
                            className="d-inline-block align-top"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav className="d-xs-flex d-lg-none flex-row align-items-center">
                                <Image 
                                    src={props.user.image}
                                    height="30"
                                    roundedCircle
                                />
                                <Navbar.Text>
                                    {props.user.name}
                                </Navbar.Text>
                            </Nav>
                            <NavLink exact activeClassName="active" className="nav-link" to="/">Home</NavLink>
                            <NavLink activeClassName="active" className="nav-link" to="/col">Collection</NavLink>
                            <NavLink activeClassName="active" className="nav-link" to="/db">Decks</NavLink>
                            <NavLink disabled activeClassName="active" className="nav-link" to="/trade">Trade</NavLink>
                            <NavLink activeClassName="active" className="nav-link" to="/cube">Cube</NavLink>
                            <NavLink disabled activeClassName="active" className="nav-link" to="/league">League</NavLink>
                            {/*
                            <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                            </NavDropdown>
                            */}
                        </Nav>
                    </Navbar.Collapse>
                    <Nav className="d-none d-lg-flex">
                        <NavDropdown title={props.user.name}>
                            <NavDropdown.Item href="#settings">Settings</NavDropdown.Item>
                        </NavDropdown>
                        <Image 
                            src={props.user.image}
                            height="40"
                            roundedCircle
                        />
                    </Nav>
                </Container>
            </Navbar>
        </header>
        
    )
}

export default withRouter(MainNavbar)