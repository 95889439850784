import { useState } from 'react';

export default function useToken() {
  function getToken() {
    const tokenString = sessionStorage.getItem('token')
    const userToken = JSON.parse(tokenString)
    return userToken
  }

  const [token, setToken] = useState(getToken());

  async function validateToken() {
    fetch('https://api.magiccollect.nl/auth/validateToken/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({'token':token})
    })
    .then((response) => {
      return response.json();
    })
    .then((responseJSON) => {
      sessionStorage.setItem('token', JSON.stringify(responseJSON.token))
      setToken(responseJSON.token)
    })
    .catch((error) => {
        console.error(error)
    })
  }

  validateToken()

  const saveToken = userToken => {
    sessionStorage.setItem('token', JSON.stringify(userToken))
    setToken(userToken)
  }

  return {
    setToken: saveToken,
    token
  }
}