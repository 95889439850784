import  React, { useEffect, useState } from 'react'
import { Badge, Button, ButtonToolbar, Container, FormControl, Image, InputGroup, Tabs, Tab, Table } from 'react-bootstrap';
import { FaFilter, FaSearch, FaSortAlphaDown, FaStar } from 'react-icons/fa'
import CollectionDetail from '../Components/CollectionDetail';
import CollectionFilters from '../Components/CollectionFilters';



export default function Collection() {
    const [tableHeader, setTableHeader] = useState([])
    const [collection, setCollection] = useState([])
    const [detailData, setDetailData] = useState([])
    const [showDetail, setShowDetail] = useState(false)
    const [search, setSearch] = useState()
    const [filterColor, setFilterColor] = useState({mustIncludeAll:[],mustExcludeAll:[]})
    const [activeFilterCount, setActiveFilterCount] = useState(0)
    const [nextPage, setNextPage] = useState()
    const [activeTab, setActiveTab] = useState()

    const dollarEuroRate = 0.8;

    const filterBadge = (<Badge bg="secondary" style={{position:'absolute', top:'-8px'}}>{activeFilterCount}</Badge>)
    const loadMoreButton = (<Button variant="outline-secondary mt-1" style={{width:"100%"}} onClick={e => {loadMore()}}>Load more...</Button>)

    const fetchCollection = async () => {
        let filterCounter = 0;

        // Grab name
        const s = search ? '+name:'+encodeURIComponent(search) : ''

        // Colors
        let c = ''
            // Color must include all
        if(filterColor.mustIncludeAll.length > 0) {
            filterCounter++
            c += '+c:'
            filterColor.mustIncludeAll.forEach(color => {
                c += color.id
            });
        }
            // Colors must exclude all
        if(filterColor.mustExcludeAll.length > 0) {
            filterCounter++
            c += '+-c:'
            filterColor.mustExcludeAll.forEach(color => {
                c += color.id
            });
        }

        setActiveFilterCount(filterCounter)

        //await fetch('https://api.scryfall.com/cards/search?unique=prints&order=released&q=game:paper'+ s + c)
        await fetch('https://api.magiccollect.nl/collection/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ userId: 100390, url: 'https://api.scryfall.com/cards/search?unique=prints&order=released&q=game:paper' + s + c })
        })
        .then((response) => {
            return response.json();
        })
        .then((responseJSON) => {
            setCollection(responseJSON.data)
            setNextPage(responseJSON.next_page)
            setActiveTab('')
        })
        .catch((error) => {
            console.log(error);
        });
    }
    const loadMore = async () => {
        await fetch('https://api.magiccollect.nl/collection/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ userId: 100390, url: nextPage })
        })
        .then((response) => {
            return response.json();
        })
        .then((responseJSON) => {
            setCollection(collection.concat(responseJSON.data))
            setNextPage(responseJSON.next_page)
            console.log('LOADED MORE');
        })
        .catch((error) => {
            console.log(error);
        });
    }

    useEffect(() => {
        setTableHeader(['Set','#','Name','','Have','Want','Value'])
        fetchCollection()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setTableHeader, setCollection, setNextPage])

    const renderTableHeader = (data,index) => {
        data = data[1]
        return(
            <th>{data}</th>
        )
    }
    const renderTableRow = (data,index) => {
        data = data[1]
        /*
        let haveTotal = 0
        let wantTotal = 0
        let minValue = 10000000000000.00
        let maxValue = 0.00
        let value = ''

        for (var finish in data.collection) {
            if (data.collection.hasOwnProperty(finish)) {
                haveTotal += parseInt(data.collection[finish].have)
                wantTotal += parseInt(data.collection[finish].want)
            }
        }
        for (var price in data.prices) {
            if(data.prices.hasOwnProperty(price) && price.includes('usd')) {
                minValue = parseFloat(data.prices[price]) < minValue ? parseFloat(data.prices[price]) : minValue
                maxValue = parseFloat(data.prices[price]) > maxValue ? parseFloat(data.prices[price]) : maxValue
            }
        }
        minValue = minValue == 10000000000000.00 ? 0.00 : minValue
        minValue = minValue * dollarEuroRate
        maxValue = maxValue * dollarEuroRate
        value = minValue == maxValue 
            ? minValue > 0.00 
                ? '€ ' + String(minValue.toFixed(2)) 
                : 'N/A'
            : '€ ' + String(minValue.toFixed(2)) + ' - ' + String(maxValue.toFixed(2))

        let values = ''
        values += data.prices.usd ? '<FaStar /> ' + '€ ' + data.prices.usd : ''
        */
        
        return (
            <tr key={data.id} onClick={e => {setDetailData(data); setShowDetail(true)}}>
                <td>
                    <div className='d-flex justify-content-center align-items-center' style={{height:'20px',width:'20px',float:'left'}}>
                        <Image style={{maxHeight:'20px',maxWidth:'20px'}} src={"https://www.magiccollect.nl/content/images/sets/" + data.set + ".svg"}/>
                    </div>
                    <span className="d-none d-sm-inline-block mx-2">{data.set_name}</span>
                    <span className="d-inline-block d-sm-none mx-2">{data.set}</span>
                </td>
                <td>{data.collector_number}</td>
                <td><span className='d-flex align-items-center'>{data.name}</span></td>
                <td className='px-0'>
                    <div>{data.finishes.includes('nonfoil') ? (<FaStar style={{color:'gray',marginRight:'2px'}}/>) : ''}</div>
                    <div>{data.finishes.includes('foil') ? (<FaStar style={{color:'gold',stroke:'black',strokeWidth:20,marginRight:'2px'}}/>) : ''}</div>
                    <div>{data.finishes.includes('etched') ? (<FaStar style={{color:'palevioletred',marginRight:'2px'}}/>) : ''}</div>
                    <div>{data.finishes.includes('glossy') ? (<FaStar style={{color:'purple',marginRight:'2px'}}/>) : ''}</div>
                </td>
                <td className='px-0'>
                    <div className='justify-content-center'>{data.finishes.includes('nonfoil') ? data.collection.nonfoil.have  : ''}</div>
                    <div className='justify-content-center'>{data.finishes.includes('foil')    ? data.collection.foil.have     : ''}</div>
                    <div className='justify-content-center'>{data.finishes.includes('etched')  ? data.collection.etched.have   : ''}</div>
                    <div className='justify-content-center'>{data.finishes.includes('glossy')  ? data.collection.glossy.have   : ''}</div>
                </td>
                <td className='px-0'>
                    <div className='justify-content-center'>{data.finishes.includes('nonfoil') ? data.collection.nonfoil.want  : ''}</div>
                    <div className='justify-content-center'>{data.finishes.includes('foil')    ? data.collection.foil.want     : ''}</div>
                    <div className='justify-content-center'>{data.finishes.includes('etched')  ? data.collection.etched.want   : ''}</div>
                    <div className='justify-content-center'>{data.finishes.includes('glossy')  ? data.collection.glossy.want   : ''}</div>
                </td>
                <td className='px-0'>
                    <div>{data.finishes.includes('nonfoil') ? data.prices.usd           ? '€ ' + parseFloat(data.prices.usd * dollarEuroRate).toFixed(2)        : 'N/A' : ''}</div>
                    <div>{data.finishes.includes('foil')    ? data.prices.usd_foil      ? '€ ' + parseFloat(data.prices.usd_foil * dollarEuroRate).toFixed(2)   : 'N/A' : ''}</div>
                    <div>{data.finishes.includes('etched')  ? data.prices.usd_etched    ? '€ ' + parseFloat(data.prices.usd_etched * dollarEuroRate).toFixed(2) : 'N/A' : ''}</div>
                    <div>{data.finishes.includes('glossy')  ? data.prices.usd_glossy    ? '€ ' + parseFloat(data.prices.usd_glossy * dollarEuroRate).toFixed(2) : 'N/A' : ''}</div>
                </td>
            </tr>
        )
    }

    return (
        <Container className="mb-5">
            <ButtonToolbar className="justify-content-end py-3 mt-3 border-top border-dark">
                <CollectionDetail showDetail={showDetail} setShowDetail={setShowDetail} detailData={detailData} setDetailData={setDetailData}/>
                <Button size="sm" variant="outline-secondary mx-1" onClick={e => (activeTab !== 'sorting') ? setActiveTab('sorting') : setActiveTab('')}><FaSortAlphaDown /></Button>
                <Button size="sm" variant="outline-secondary mx-1" onClick={e => (activeTab !== 'filters') ? setActiveTab('filters') : setActiveTab('')} style={{position:'relative'}}>
                    <FaFilter />
                    { activeFilterCount > 0 ? filterBadge : ''}
                </Button>
                <InputGroup size="sm" style={{paddingLeft: '2rem'}}>
                    <FormControl
                        type="text"
                        placeholder="Search for a card"
                        aria-label="Search for a card"
                        aria-describedby="btnGroupAddon"
                        onChange={e => {setSearch(e.target.value)}}
                        onKeyPress={e => { if(e.key === "Enter") {fetchCollection()}}}
                    />
                    <Button className='d-flex align-items-center' variant="outline-secondary" onClick={e => {fetchCollection()}}><FaSearch/></Button>
                </InputGroup>
            </ButtonToolbar>
            <Container className="border-bottom border-dark px-0 mx-0 mb-3">
                <Tabs defaultActiveKey="" activeKey={activeTab} className="border-0">
                    <Tab eventKey="sorting" className="border-top border-dark p-2">
                        All the sorting rules here
                    </Tab>
                    <Tab eventKey="filters" className="border-top border-dark p-2">
                        <CollectionFilters 
                            filterColor={filterColor} 
                            setFilterColor={setFilterColor} 
                            applyFilters={fetchCollection}
                        />
                    </Tab>
                </Tabs>
            </Container>
            <Table striped hover className="mb-0 collectionTable" style={{cursor:'pointer'}}>
                <thead>
                    <tr>
                        {
                            tableHeader && Object.entries(tableHeader).map(renderTableHeader)
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        collection && Object.entries(collection).map(renderTableRow)
                    }
                </tbody>
            </Table>
            { nextPage ? loadMoreButton : ''}
        </Container>
    )
}
