import React, { useEffect, useState } from 'react'
import { Form, FormControl, InputGroup, Button } from 'react-bootstrap';
import { FaUser } from 'react-icons/fa';
import background from '../Data/backgroundVideos';

async function loginUser(credentials) {
    return fetch('https://api.magiccollect.nl/auth/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
    .then(data => data.json())
    .catch((error) => {
        console.error(error);
    });
}

export default function Login({setToken}) {
    const [bg, setBg] = useState([])
    const [username, setUsername] = useState()
    const [password, setPassword] = useState()
    const [error, setError] = useState()

    const handleSubmit = async (e) => {
        e.preventDefault();
        const auth = await loginUser({
            username,
            password
        })
        if(auth.status === 'ok') {
            setToken(auth.token)
        } else {
            setError(auth.status)
        }
    }
    useEffect(() => {
        setBg(background[Math.floor(Math.random()*background.length)])
    }, [setBg])
    
    return (
        <>
            <video 
                autoPlay
                loop
                muted
                poster={bg.img}
                style={{position:'fixed',minWidth:'100%',minHeight:'100%',top:'50%',left:'50%',transform:'translate(-50%,-50%)'}}
            >
                <source type="video/mp4" controls={true} src={bg.video} />
                <img src={bg.img} alt=""/>
            </video>
            <div className='login-main'>
                <div className='login'>
                    { error ?
                        <div style={{borderRadius:'10px', padding:'2px 10px', background:'darkred',color:'white',textAlign:'center'}}>{ error ? error : '' }</div>
                        : ''
                    }
                    <Form onSubmit={handleSubmit}>
                        <InputGroup>
                            <InputGroup.Text>
                                <FaUser />
                            </InputGroup.Text>
                            <FormControl
                                type="text"
                                placeholder="Username"
                                aria-label="Username"
                                onChange={e => {setUsername(e.target.value)}}
                            />
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Text>
                                <FaUser />
                            </InputGroup.Text>
                            <FormControl
                                type="password"
                                placeholder="Password"
                                aria-label="Password"
                                onChange={e => {setPassword(e.target.value)}}
                            />
                        </InputGroup>
                        <Button type="submit" style={{background:bg.button,color:bg.color}}>Start building!</Button>
                    </Form>
                </div>
            </div>
        </>
    )
}
