import React, { useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import DeckCard from '../Components/DeckCard'

export default function Decks() {
    /*
    let decks = []

    fetch('https://api.magiccollect.nl/decks/')
        .then((response) => {
            return response.json()
        })
        .then((data) => {
            decks = data;
        })
        .catch((error) => {
            console.error(error);
        });
    */
    const [decks, setDecks] = useState({decks:[]})

    useEffect(() => {
        const fetchDecks = async () => {
            await fetch('https://api.magiccollect.nl/decks/')
            .then((response) => {
                return response.json()
            })
            .then((responseJSON) => {
                setDecks({decks: responseJSON.data})
            })
            .catch((error) => {
                console.error(error);
            });
        }
        fetchDecks()
    }, [setDecks])

    const renderCard = (deck,index) => {
        return(
            <DeckCard
                key={deck.id}
                id={deck.id}
                title={deck.name}
                img={deck.imageThumbnail}
                authorId={deck.authorId}
                author={deck.author}
                authorImg={deck.authorImage}
                colors={deck.colors}
                format={deck.format}
                data={deck}
            />
        )
    }

    return (
        <Container>
            <Row>
                {
                    decks.decks && decks.decks.map(renderCard)
                }
            </Row>
        </Container>
    )
}
