import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import MainNavbar from './Components/MainNavbar'
import Home from './Pages/Home'
import Decks from './Pages/Decks'
import Collection from './Pages/Collection'
import Cube from './Pages/Cube'
import NotFound from './Pages/NotFound'
import Deck from './Pages/Deck'
import Login from './Pages/Login'
import useToken from './Components/useToken'


export default function App() {
  const { token, setToken } = useToken()
  const [ user, setUser ] = useState([])

  useEffect(() => {
    fetch('https://api.magiccollect.nl/user/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({'token': token})
    })
    .then((response) => {
        return response.json();
    })
    .then((responseJSON) => {
        setUser(responseJSON)
        sessionStorage.setItem('magiccollect', JSON.stringify(responseJSON))
    })
    .catch((error) => {
        console.log(error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token])

  if(!token || token === '') {
    return <Login setToken={setToken} />
  }

  return (
    <>
    <Router>
      <MainNavbar user={user} />
      <Switch>
        <Route path="/" exact component={() => <Home />} />
        <Route path="/db" exact component={() => <Decks />} />
          <Route path="/db/deck" exact component={() => <Deck />} />
        <Route path="/col" exact component={() => <Collection />} />
        <Route path="/cube" exact component={() => <Cube />} />
        
        <Route component={() => <NotFound />} />
      </Switch>
    </Router>
    </>
  )
}
